import * as React from 'react';
import { ChangeEvent } from 'react';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import '@rmwc/icon/styles';

import { FeatherIcon } from './feather_icon';

export interface FilterInputProps {
  value: string;
  update: (data: string) => void;
};

export const FilterInput: React.SFC<FilterInputProps> = ({ value, update }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    update(e.target.value);
  };

  const handleReset = () => {
    update('');
  };

  const trailingIcon = {
    icon: 'close',
    tabIndex: 0,
    onClick: handleReset,
  };

  return <span className="filter_input">
    <TextField placeholder="Search ..." value={value} onChange={handleChange} trailingIcon={trailingIcon} />
  </span>
};
