export interface ColorType {
  r: number;
  g: number;
  b: number;
};

export type ColorPoint = [number,ColorType];

export const colorToString = (color: ColorType) => {
  const { r, g, b } = color;
  return `rgb(${r}, ${g}, ${b})`;
}

export class ColorGradient {
  points: Array<ColorPoint>;

  constructor(points: Array<ColorPoint>=[]) {
    this.points = points;
  }

  addPoint(value: number, color: ColorType) {
    this.points.push([value, color]);
  }

  getColor(value: number): ColorType {
    // before first value?

    if(value < this.points[0][0]) {
      return this.points[0][1];
    }

    // in between values?

    for(let i = 1; i < this.points.length; ++i) {
      const [endValue, endColor] = this.points[i];

      if(endValue < value) {
        continue;
      }

      const [startValue, startColor] = this.points[i-1];
      const progress = (value - startValue) / (endValue - startValue);

      const calcChannel = (start: number, end: number) => {
        return start + (end - start) * progress;
      };

      return {
        r: calcChannel(startColor.r, endColor.r),
        g: calcChannel(startColor.g, endColor.g),
        b: calcChannel(startColor.b, endColor.b),
      };
    }

    // after last value

    return this.points[this.points.length-1][1];
  }

  getColorString(value: number): string {
    const color = this.getColor(value);
    return colorToString(color);
  }
}
