import * as React from 'react';
import { SiteOverviewData, TrackerData } from '../types';

import { MapView, MapMarker } from './map';
import { UiState } from './ui_state';
import { getSiteState } from '../state_calc';

export interface SiteMapProps {
  sites: SiteOverviewData[] | undefined;
  uiState: UiState;
};

export const SiteMap: React.SFC<SiteMapProps> = ({ sites, uiState }) => {
  const positionedSites = React.useMemo(() => {
    if(sites == null) {
      return undefined;
    }

    return sites
      .map((site): [SiteOverviewData, google.maps.LatLngLiteral | null] => {
        const position = (site.lat != null && site.long != null) ? { lat: site.lat, lng: site.long } : null;
        return [site, position];
      })
      .filter(([site, position]) => {
        return position != null;
      });
  }, [sites]);

  if(positionedSites == null) {
    return null;
  }

  const markers = positionedSites.map(([site, position]) => {
      let color: string;

      // TODO
      switch(getSiteState(site)) {
        case 'error':
          color = '#ff0000';
          break;

        case 'warning':
          color = '#ffae40';
          break;

        case 'okay':
          color = '#00ff00';
          break;

        default:
          color = '#ff0000';
          break;
      }

      return <MapMarker key={site.id} title={site.name} position={position!} color={color} id={site.id} uiState={uiState} />;
    });

  return <MapView>
    {markers}
  </MapView>
};
