import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { useState } from 'react';
import { getUpload } from '../api';
import { loadUploadDeltas } from '../import';
import { SiteData } from '../types';
import { useUpdateUpload } from './backend';
import { ImportData, ImportDialog } from './import_dialog';

import { UploadsListDialog } from './uploads_list_dialog';

export interface UploadsDialogProps {
  id: string;
  onClosed: () => void;
  site: SiteData;
};

export const UploadsDialog: React.FC<UploadsDialogProps> = ({ id, onClosed, site }) => {
  const [data, setData] = useState<Promise<ImportData>>();
  const mutation = useUpdateUpload();
  const { keycloak } = useKeycloak();

  const getData = async (site: string, id: string): Promise<ImportData> => {
    if(keycloak.token == null) {
      throw new Error("Login token not found");
    }

    const upload = await getUpload(site, id, keycloak.token);

    let user_comment = upload.comment.split('\n')[0].trim();

    if(user_comment.length === 0) {
      const formatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' });
      user_comment = `${formatter.format(upload.date)}`;
    }

    return {
      deltas: loadUploadDeltas(upload),
      comment: `Importing upload '${user_comment}'`,
      merge: true,
      onSuccess: () => {
        mutation.mutateAsync({
          site: site,
          upload: id,
          data: {
            pending: false,
          },
        });
      }
    };
  };

  const doImport = (upload: string) => {
    setData(getData(id, upload));
  };

  if(data != null) {
    return <ImportDialog site={site} data={data} onClosed={onClosed} />
  } else {
    return <UploadsListDialog doImport={doImport} id={id} onClosed={onClosed} />
  }
};
