import { FileMapping, SheetCell } from "./types";

const mapHomeAngle = (value: string | number | null): number => {
  if(value === "West" || value === "west") {
    return -1;
  }
  return 1;
}

const revertHomeAngle = (value: string | number | null): string => {
  return value == -1 ? "West" : "East";
}

const mapYes = (value: string | number | null): number => {
  if(value === "Yes" || value === "yes") {
    return 1;
  }
  return 0;
}

const revertYes = (value: string | number | null): string => {
  return value == 1 ? "Yes" : "No";
}

const general: SheetCell[] = [
  { label: 'Home angle direction', key: 'home_angle_direction', col: 1, row: 6, mapValue: mapHomeAngle, revertValue: revertHomeAngle, },
  { label: 'Home angle [°]', key: 'home_angle', col: 1, row: 7, },
  { label: 'Row/axis pitch [m]', key: 'row_axis_pitch', col: 1, row: 8, },
  { label: 'Panel width [m]', key: 'panel_width', col: 1, row: 9, },
  { label: 'General azimuth angle [°]', key: 'general_azimuth_angle', col: 1, row: 10, },
  { label: 'Set all tracker to azimuth angle [°]', key: 'set_tracker_to_azimuth_angle', col: 1, row: 11, mapValue: mapYes, revertValue: revertYes, },
  { label: 'General module size tolerance [%]', key: 'general_module_size_tolerance', col: 1, row: 12, },
  { label: 'Set all tracker to module size tolerance [°]', key: 'set_tracker_to_tolerance', col: 1, row: 13, mapValue: mapYes, revertValue: revertYes, },
];

const axis: SheetCell[] = [
  { label: 'West Turning Limit [°]', key: 'west_turning_limit', col: 1, row: 16, },
  { label: 'East Turning Limit [°]', key: 'east_turning_limit', col: 1, row: 17, },
];

const motor: SheetCell[] = [
  { label: 'Shortterm Motor Current [A]', key: 'short_motor_current', col: 1, row: 22, },
  { label: 'Longterm Motor Current [A]', key: 'long_motor_current', col: 1, row: 23, },
  { label: 'Nominal Voltage Motor [V]', key: 'voltage_motor', col: 1, row: 24, },
];

const safe: SheetCell[] = [
  { label: 'Wind Position [°]', key: 'wind_position', col: 5, row: 6, },
  { label: 'Use adaptable Wind Position', key: 'use_wind_position', col: 5, row: 7, },
  { label: 'Snow Position [°]', key: 'snow_position', col: 5, row: 8, },
  { label: 'Cleaning Position [°]', key: 'cleaning_position', col: 5, row: 9, },
  { label: 'Cleaning V-Position', key: 'cleaning_v_position', col: 5, row: 10, mapValue: mapYes, revertValue: revertYes, },
  { label: 'Harvesting Position', key: 'harvesting_position', col: 5, row: 11, },
  { label: 'Flood Sensor', key: 'flood_sensor', col: 5, row: 12, },
];

const wind: SheetCell[] = [
  { label: 'Wind Alarm [km/h]', key: 'wind_alarm_kmh', col: 5, row: 15, },
  { label: 'Wind Alarm [m/s]', key: 'wind_alarm_ms', col: 5, row: 16, },
  { label: 'Gust Wind [km/h]', key: 'gust_wind_kmh', col: 5, row: 17, },
  { label: 'Gust Wind [m/s]', key: 'gust_wind_ms', col: 5, row: 18, },
];

const wind_time: SheetCell[] = [
  { label: 'Alarm Wind Time [s]', key: 'wind_time_alarm', col: 5, row: 21, },
  { label: 'No Alarm Wind Time [s]', key: 'wind_time_no_alarm', col: 5, row: 22, },
];

const snow: SheetCell[] = [
  { label: 'Snow height alarm [m]', key: 'snow_alarm', col: 5, row: 25, },
];

const irra: SheetCell[] = [
  { label: 'Minimum Irradiation [W/m²]', key: 'min_irradiation', col: 5, row: 28, },
];

export const allParameterCells = [ ...general, ...axis, ...motor, ...safe, ...wind, ...wind_time, ...snow, ...irra ];

export const allParameterViews: [string, SheetCell[]][] = [
  ['General Tracker Parameter', general],
  ['Axis Parameter', axis],
  ['Motor Parameter', motor],
  ['Safe Parameter Position', safe],
  ['Wind Speed Configuration', wind],
  ['Wind Time Configuration', wind_time],
  ['Snow Configuration', snow],
  ['Irradiation Configuration', irra]
];

export const fileTypes: FileMapping[]  = [
  {
    name: 'Gantner App Data',
    merge: true,
    sheets: [
      {
        columns: [
          ['Tracker', ['id']],
          ['Controller', ['controller']],
          ['Calibration', ['calibration']],
        ],
      },
    ],
  },
  {
    name: 'Gantner App Data',
    merge: true,
    sheets: [
      {
        columns: [
          ['plant', []],
          ['controller_serial', ['controller']],
          ['serial_number', ['id']],
          ['latitude',  ['secondary_lat']],
          ['longitude', ['secondary_long']],
          ['rotation', ['calibration']],
        ],
      },
    ],
  },
  {
    name: 'Portal Export Data',
    merge: true,
    sheets: [
      {
        columns: [
          ['id', ['id']],
          ['name', ['name']],
          ['controller', ['controller']],
          ['address', ['address']],
          ['calibration', ['calibration']],
          ['lat', ['lat']],
          ['long', ['long']],
          ['elevation', ['elevation']],
          ['group', ['group']],
          ['cleaning', ['cleaning']],
        ],
      },
    ],
  },
  { // version 1
    name: 'ZIM Track Commissioning V1',
    merge: false,
    sheets: [
      {
        name: 'Track.bloxx settings',
        columns: [
          ['ZIM Track Tracker Name', ['id']],
          ['Customer Tracker Name', ['name']],
          ['Drive Unit Serial Nr', ['drive']],
          [/^Track.bloxx GPS Coordinate.*/, ['lat']],
          [null, ['long']],
          [null, ['elevation']],
          ['Group', ['group']],
          [/^Cleaning Position.*/, ['cleaning']],
        ],
      },
    ],
  },
  { // version 4
    name: 'ZIM Track Commissioning V4',
    merge: false,
    sheets: [
      {
        name: 'Track.bloxx settings',
        columns: [
          ['ZIM Track Tracker Name', ['id']],
          ['Customer Tracker Name', ['name']],
          ['Drive Unit Serial Nr', ['drive']],
          [/^First Pole.*/i, ['first_long']],
          [null, ['first_lat']],
          [null, ['first_elevation']],
          [/^Middle Pole.*/i, ['long']],
          [null, ['lat']],
          [null, ['elevation']],
          [/^Last Pole.*/i, ['last_long']],
          [null, ['last_lat']],
          [null, ['last_elevation']],
          [/^Cardan join[t]? C1.*/i, ['joint_north_long']], // yes, no t
          [null, ['joint_north_lat']],
          [null, ['joint_north_elevation']],
          [/^Cardan Joint C2.*/i, ['joint_south_long']],
          [null, ['joint_south_lat']],
          [null, ['joint_south_elevation']],
          ['Backtracking Group', ['backtracking_group']],
          ['Cleaning Group', ['group']],
          [/^Cleaning Position.*/, ['cleaning']],
          [/^Azimuth angle.*/, []], // ignore
          [/^Tracking Mode.*/, ['tracking_mode']],
          [/^Module size tracking tolerance.*/, ['length_tolerance']],
          ['Communication Group WTC', ['gateway']],
          ['Monitoring Group', ['monitoring_group']],
        ],
      },
      {
        name: 'Tracker Parameter',
        columns: [],
        cells: allParameterCells,
      },
      {
        name: 'Site Information',
        data_row_offset: 18,
        columns: [
          ['Devices', ['name']],
          ['Nr', ['nr']],
          ['Name comm. layout', ['id']],
          [null, ['address_qreader']],
          [null, ['address_ipc']],
          [null, ['address_switch']],
          ['Crosscheck communication overview plan', ['sensor_1']],
          [null, ['sensor_2']],
          [null, ['sensor_3']],
          [null, ['antenna']],
          ['Mast', ['pole']],
          [null, ['lng']],
          [null, ['lat']],
          [null, ['tracker_count']],
        ],
      },
    ],
  },
  { // version 3.1 with Monitoring Group
    name: 'ZIM Track Commissioning V3.1',
    merge: false,
    sheets: [
      {
        name: 'Track.bloxx settings',
        columns: [
          ['ZIM Track Tracker Name', ['id']],
          ['Customer Tracker Name', ['name']],
          ['Drive Unit Serial Nr', ['drive']],
          [/^First Pole.*/i, ['first_long']],
          [null, ['first_lat']],
          [null, ['first_elevation']],
          [/^Middle Pole.*/i, ['long']],
          [null, ['lat']],
          [null, ['elevation']],
          [/^Last Pole.*/i, ['last_long']],
          [null, ['last_lat']],
          [null, ['last_elevation']],
          ['Backtracking Group', ['backtracking_group']],
          ['Cleaning Group', ['group']],
          [/^Cleaning Position.*/, ['cleaning']],
          [/^Azimuth angle.*/, []], // ignore
          [/^Module size tracking tolerance.*/, ['length_tolerance']],
          ['Communication Group WTC', ['gateway']],
          ['Monitoring Group', ['monitoring_group']],
        ],
      },
      {
        name: 'Tracker Parameter',
        columns: [],
        cells: allParameterCells,
      },
      {
        name: 'Site Information',
        data_row_offset: 18,
        columns: [
          ['Devices', ['name']],
          ['Nr', ['nr']],
          ['Name comm. layout', ['id']],
          [null, ['address_qreader']],
          [null, ['address_ipc']],
          [null, ['address_switch']],
          ['Crosscheck communication overview plan', ['sensor_1']],
          [null, ['sensor_2']],
          [null, ['sensor_3']],
          [null, ['antenna']],
          ['Mast', ['pole']],
          [null, ['lng']],
          [null, ['lat']],
          [null, ['tracker_count']],
        ],
      },
    ],
  },
  { // version 3.0 without Monitoring Group
    name: 'ZIM Track Commissioning V3.0',
    merge: false,
    sheets: [
      {
        name: 'Track.bloxx settings',
        columns: [
          ['ZIM Track Tracker Name', ['id']],
          ['Customer Tracker Name', ['name']],
          ['Drive Unit Serial Nr', ['drive']],
          [/^First Pole.*/i, ['first_long']],
          [null, ['first_lat']],
          [null, ['first_elevation']],
          [/^Middle Pole.*/i, ['long']],
          [null, ['lat']],
          [null, ['elevation']],
          [/^Last Pole.*/i, ['last_long']],
          [null, ['last_lat']],
          [null, ['last_elevation']],
          ['Backtracking Group', ['backtracking_group']],
          ['Cleaning Group', ['group']],
          [/^Cleaning Position.*/, ['cleaning']],
          [/^Azimuth angle.*/, []], // ignore
          [/^Module size tracking tolerance.*/, ['length_tolerance']],
          ['Communication Group WTC', ['gateway']],
        ],
      },
      {
        name: 'Tracker Parameter',
        columns: [],
        cells: allParameterCells,
      },
      {
        name: 'Site Information',
        data_row_offset: 18,
        columns: [
          ['Devices', ['name']],
          ['Nr', ['nr']],
          ['Name comm. layout', ['id']],
          [null, ['address_qreader']],
          [null, ['address_ipc']],
          [null, ['address_switch']],
          ['Crosscheck communication overview plan', ['sensor_1']],
          [null, ['sensor_2']],
          [null, ['sensor_3']],
          [null, ['antenna']],
          ['Mast', ['pole']],
          [null, ['lng']],
          [null, ['lat']],
          [null, ['tracker_count']],
        ],
      },
    ],
  },
  { // version 2
    name: 'ZIM Track Commissioning V2',
    merge: false,
    sheets: [
      {
        name: 'Track.bloxx settings',
        columns: [
          ['ZIM Track Tracker Name', ['id']],
          ['Customer Tracker Name', ['name']],
          ['Drive Unit Serial Nr', ['drive']],
          [/^First Pole.*/i, ['first_long']],
          [null, ['first_lat']],
          [null, ['first_elevation']],
          [/^Middle Pole.*/i, ['long']],
          [null, ['lat']],
          [null, ['elevation']],
          [/^Last Pole.*/i, ['last_long']],
          [null, ['last_lat']],
          [null, ['last_elevation']],
          ['Backtracking Group', ['backtracking_group']],
          ['Cleaning Group', ['group']],
          [/^Cleaning Position.*/, ['cleaning']],
        ],
      },
    ],
  },
];