import * as React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { SiteData } from '../types';
import { getTrackerArrayState } from '../state_calc';
import { StateIndicator } from './state_indicator';
import { useSite } from './backend';

export interface SiteHeaderProps {
  site: SiteData | undefined;
}

export const SiteHeader: React.FC<SiteHeaderProps> = ({ site }) => {
  const showingParams = window.location.pathname.indexOf('/params') != -1;
  const showingComm = window.location.pathname.indexOf('/communication') != -1;
  const showingHistory = window.location.pathname.indexOf('/history') != -1;
  const showingSiteHistory = window.location.pathname.indexOf('/sitehistory') != -1;
  const showingSiteComment = window.location.pathname.indexOf('/comment') != -1;
  const activeCss = 'mdc-ripple-upgraded--background-focused';
  const entries = site?.trackers;

  const state = React.useMemo(() => {
    if(entries != null) {
      return getTrackerArrayState(entries);
    } else {
      return 'ignore';
    }
  }, [entries]);

  return <h2>
    <span className="site_state"><StateIndicator state={state} /></span>
    <span className="site_title">{site?.name ?? 'Loading ...'}</span>
    <span className="site_menu">
      <Button dense tag={Link} to={`/site/${site?.id}`} className={ (showingParams || showingComm || showingHistory || showingSiteHistory || showingSiteComment) ? undefined : activeCss }>Tracker</Button>
      <Button dense tag={Link} to={`/site/${site?.id}/params`} className={ showingParams ? activeCss : undefined }>Parameter</Button>
      <Button dense tag={Link} to={`/site/${site?.id}/communication`} className={ showingComm ? activeCss : undefined }>Communication</Button>
      <Button dense tag={Link} to={`/site/${site?.id}/comment`} className={ showingSiteComment ? activeCss : undefined }>Comment</Button>
      <Button dense tag={Link} to={`/site/${site?.id}/sitehistory`} className={ showingSiteHistory ? activeCss : undefined }>History</Button>
    </span>
  </h2>;
}

export interface SiteHeaderWrapperProps {
  id: string;
}

export const SiteHeaderWrapper: React.FC<SiteHeaderWrapperProps> = ({ id }) => {
  const siteQuery = useSite(id);
  const site = siteQuery.data;
  return <SiteHeader site={site} />;
}