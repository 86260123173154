import * as React from 'react';
import { useMemo, useState } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { TrackerData, SelectableStateType } from '../types';
import { getTrackerState, getTrackerArrayState } from '../state_calc';
import { ImportButton } from './import_button';
import { TrackerList } from './tracker_list';
import { FilterInput } from './filter_input';
import { TrackerStateChoice } from './tracker_state_choice';
import { TrackerMap } from './tracker_map';
import { useUiState } from './ui_state';
import { useSite } from './backend';
import { UploadsButton } from './uploads_button';
import { ExportButton } from './export_button';
import { SiteHeader } from './site_header';

const useSortTrackers = (trackers: TrackerData[] | undefined) => {
  return useMemo(() => {
    if(trackers == null) {
      return undefined;
    }

    return Array.from(trackers.values()).sort((dataA, dataB) => {
      const nameA = typeof dataA.id === 'string' ? dataA.id : '';
      const nameB = typeof dataB.id === 'string' ? dataB.id : '';

      return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
    });
  }, [trackers]);
}

const useStateFilter = (entries: TrackerData[] | undefined, state: SelectableStateType | undefined) => {
  return useMemo(() => {
    if(entries == null) {
      return undefined;
    }

    if(state == null || state === 'all') {
      return entries;
    }

    return entries.filter((data) => {
      return getTrackerState(data) === state;
    });
  }, [entries, state]);
}

const useSearchFilter = (entries: TrackerData[] | undefined, filter: string | undefined) => {
  return useMemo(() => {
    if(entries == null) {
      return undefined;
    }

    if(filter == null || filter === '') {
      return entries;
    }

    const lowerFilter = filter.toLowerCase();

    return entries.filter((data) => {
      for(const key of ['id', 'name', 'controller']) {
        const value = data[key];

        if(typeof value === 'string' && value.toLowerCase().indexOf(lowerFilter) >= 0) {
          return true;
        }
      }

      return false;
    });
  }, [entries, filter]);
}

export interface SiteViewProps {
  id: string;
}

export const SiteView: React.FC<SiteViewProps> = ({ id }) => {
  const siteQuery = useSite(id);

  const [filter, setFilter] = useState<string>('');
  const [state, setState] = useState<SelectableStateType>('all');

  const uiState = useUiState();

  const site = siteQuery.data;
  const entries = useSortTrackers(site?.trackers);
  const searchFiltered = useSearchFilter(entries, filter);
  const stateFiltered = useStateFilter(searchFiltered, state);

  const filteredTrackers = stateFiltered;

  return <div className="site">
    <div className="header">
      <SiteHeader site={site} />
      <div className="filter_bar">
        <TrackerStateChoice value={state} update={setState} trackers={searchFiltered} />
        <FilterInput value={filter} update={setFilter} />
      </div>
    </div>
    <div className="content">
      <div className="list">
        <TrackerList siteId={site?.id} trackers={filteredTrackers} empty={entries?.length == 0} uiState={uiState} />
        <div className="button_bar">
          <ImportButton site={site} />
          <UploadsButton id={id} site={site} />
          <ExportButton site={site} />
        </div>
      </div>
      <div className="map">
        <TrackerMap trackers={searchFiltered} uiState={uiState} comms={site?.communication} colorMode={'normal'} />
      </div>
    </div>
  </div>;
}
