import * as React from 'react';
import { IgnorableStateType } from '../types';
import { FeatherIcon } from './feather_icon';

export interface StateIndicatorProps {
  state: IgnorableStateType;
};

export const StateIndicator: React.SFC<StateIndicatorProps> = ({ state }) => {
  let icon = 'circle';
  let color = 'black';

  switch(state) {
    case 'error':
      icon = 'alert-circle';
      color = 'red';
      break;

    case 'warning':
      icon = 'help-circle';
      color = 'orange';
      break;

    case 'okay':
      icon = 'check-circle';
      color = 'green';
      break;

    case 'ignore':
      icon = 'circle';
      color = 'lightgray';
      break;
  }

  const style = {
    color: color,
  };

  return <FeatherIcon style={style} icon={icon} />;
};
