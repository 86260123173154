import { HistoryData, SiteData, SiteOverviewData, UploadSiteData, UploadData, UploadOverviewData } from "./types";

export const getSiteList = async (token: string): Promise<SiteOverviewData[]> => {
  const response = await fetch('/api/v2/sites?scope=write', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const getSiteUploadList = async (token: string): Promise<UploadSiteData[]> => {
  const response = await fetch('/api/v2/sites/uploads?scope=write', {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const getSite = async (id: string, token: string): Promise<SiteData> => {
  const response = await fetch(`/api/v2/sites/${encodeURIComponent(id)}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const getUpload = async (site: string, upload: string, token: string): Promise<UploadData> => {
  const response = await fetch(`/api/v2/sites/${encodeURIComponent(site)}/uploads/${encodeURIComponent(upload)}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  const data = await response.json();

  return {
    ...data,
    date: new Date(data.date),
  }
};

export const getSiteUploads = async (id: string, token: string): Promise<UploadOverviewData[]> => {
  const response = await fetch(`/api/v2/sites/${encodeURIComponent(id)}/uploads`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  const data = await response.json();

  return data.map((entry: any) => {
    return {
      ...entry,
      date: new Date(entry.date),
    }
  });
};

export const createSite = async (site: Omit<SiteData,'id'>, token: string): Promise<string> => {
  const response = await fetch(`/api/v2/sites`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(site),
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const updateSite = async (site: Omit<Partial<SiteData>,'version'|'id'> & Pick<SiteData,'id'> & { comment: string }, token: string): Promise<SiteData> => {
  const { id, ...other } = site;

  const response = await fetch(`/api/v2/sites/${id}`, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(other),
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const updateUpload = async (site: string, upload: string, data: Partial<Pick<UploadData,'pending'>>, token: string): Promise<UploadData> => {
  const response = await fetch(`/api/v2/sites/${encodeURIComponent(site)}/uploads/${encodeURIComponent(upload)}`, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  const result = await response.json();

  return {
    ...result,
    date: new Date(result.date),
  }
};

export const deleteSite = async (id: string, token: string): Promise<void> => {
  const response = await fetch(`/api/v2/sites/${id}`, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const addBoxes = async (data: Array<[string,string]>, token: string) => {
  const response = await fetch('/api/v2/boxes', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }
};

export const matchBoxes = async (ids: string[], token: string): Promise<Record<string,string>> => {
  const batches = Array<string[]>();

  const maxAmount = 200;
  let offset = 0;

  while(offset < ids.length) {
    batches.push(ids.slice(offset, offset + maxAmount));
    offset += maxAmount;
  }

  const result = {};

  for(const batch of batches) {
    const response = await fetch(`/api/v2/boxes?ids=${encodeURIComponent(batch.join(','))}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if(response.status >= 400) {
      throw new Error("Unable to fetch");
    }

    const batchResult = await response.json();
    Object.assign(result, batchResult);
  }

  return result;
};

export const getBoxSerial = async (id: string, token: string): Promise<string|null> => {
  const response = await fetch(`/api/v2/boxes/${encodeURIComponent(id)}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const getTrackerHistory = async (id: string, tracker: string, token: string): Promise<HistoryData[]> => {
  const response = await fetch(`/api/v2/history/${encodeURIComponent(id)}/tracker/${encodeURIComponent(tracker)}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};

export const getSiteHistory = async (id: string, token: string): Promise<HistoryData[]> => {
  const response = await fetch(`/api/v2/history/${encodeURIComponent(id)}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if(response.status >= 400) {
    throw new Error("Unable to fetch");
  }

  return response.json();
};