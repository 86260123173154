import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';

import { FilterInput } from './filter_input';
import { CreateSiteButton } from './create_site';
import { SiteList } from './site_list';
import { useUiState } from './ui_state';
import { SiteMap } from './site_map';
import { SitePhaseChoice } from './site_phase_choice';
import { SelectableSitePhase, SiteOverviewData } from '../types';
import { useSiteList, useSiteUploadList } from './backend';

const useSortSites = (sites: SiteOverviewData[] | undefined) => {
  return useMemo(() => {
    if(sites == null) {
      return undefined;
    }

    return Array.from(sites.values()).sort((dataA, dataB) => {
      return dataA.name.localeCompare(dataB.name, undefined, { sensitivity: 'base', numeric: true });
    });
  }, [sites]);
};

const useSearchFilter = (entries: SiteOverviewData[] | undefined, filter: string | undefined) => {
  return useMemo(() => {
    if(entries == null) {
      return undefined;
    }

    if(filter == null || filter === '') {
      return entries;
    }

    const lowerFilter = filter.toLowerCase();

    return entries.filter((data) => {
      return data.name.toLowerCase().indexOf(lowerFilter) >= 0;
    });
  }, [entries, filter]);
};

const usePhaseFilter = (entries: SiteOverviewData[] | undefined, phase: SelectableSitePhase) => {
  return useMemo(() => {
    if(entries == null) {
      return undefined;
    }

    if(phase == null || phase === 'all') {
      return entries;
    }

    return entries.filter((site) => {
      return site.phase === phase;
    });
  }, [entries, phase]);
};

export interface HomeProps {
};

export const Home: React.SFC<HomeProps> = ({ }) => {
  const query = useSiteList();
  const uploads = useSiteUploadList().data;

  const [filter, setFilter] = useState<string>('');
  const [phase, setPhase] = useState<SelectableSitePhase>('all');

  const uiState = useUiState();

  const entries = useSortSites(query.data);
  const searchFiltered = useSearchFilter(entries, filter);
  const phaseFiltered = usePhaseFilter(searchFiltered, phase);

  const filtered = phaseFiltered;

  const empty = entries?.length == 0;

  return <div className="site">
    <div className="header">
      <div className='filter_bar'>
        <SitePhaseChoice sites={searchFiltered} value={phase} update={setPhase} />
        <FilterInput value={filter} update={setFilter} />
      </div>
    </div>
    <div className="content">
      <div className="list">
        <SiteList sites={filtered} uploads={uploads} empty={empty} error={query.isError} uiState={uiState} />
        <CreateSiteButton uiState={uiState} />
      </div>
      <div className="map">
        <SiteMap sites={filtered} uiState={uiState} />
      </div>
    </div>
  </div>;
};
