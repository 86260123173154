import * as React from 'react';
import { useEffect, useRef } from 'react';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { Link } from 'react-router-dom';
import { UiState, useIsActive } from './ui_state';
import { StateIndicator } from './state_indicator';
import { PhaseButton } from './phase_dialog';
import { QreaderButton } from './qreader_dialog';
import { FeatherIcon } from './feather_icon';
import { SiteOverviewData, UploadSiteData } from '../types';
import { getSiteState, getSiteTrackerState } from '../state_calc';
import { useDeleteSite } from './backend';
import { WebportalButton } from './webportal_dialog';
import { SiteNameButton } from './site_name_dialog';

interface SiteRowProps {
  site: SiteOverviewData;
  pendingCount: number | undefined;
  uiState: UiState;
}

const SiteRow: React.SFC<SiteRowProps> = ({ site, pendingCount, uiState }) => {
  const domRef = useRef<HTMLElement>(null);
  const state = getSiteState(site);
  const trackerState = getSiteTrackerState(site);
  const deleteMutation = useDeleteSite();
  const active = useIsActive(site.id, uiState);

  useEffect(() => {
    const element = domRef.current;

    if(!active || element == null) {
      return;
    }

    const parent = element.closest('div')!;
    const head = parent.querySelector('th')!;

    if(active && element) {
      const over = parent.scrollTop + head.clientHeight >= element.offsetTop;
      const under = parent.scrollTop + parent.clientHeight <= element.offsetTop + element.clientHeight;

      if(over || under) {
        const y = element.offsetTop + element.clientHeight / 2 - parent.clientHeight / 2;

        parent.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [active]);

  const remove = async () => {
    if(confirm(`Really delete '${site.name}'?`)) {
      try {
        await deleteMutation.mutateAsync(site.id);
      } catch {
        alert("Unable to delete");
      }
    }
  };

  const setRowActive = () => {
    uiState.toggleActive(site.id);
  };

  const handleEnter = () => {
    uiState.setHovered(site.id);
  };

  const handleLeave = () => {
    uiState.clearHovered(site.id);
  };

  let ctcLink;

  if(site.qreader != null) {
    const url = `https://ras.gantner-webportal.com/proxy/${site.qreader}/cgi-bin/rfsetup`;
    ctcLink = <Button tag="a" href={url} target="_blank">CTC</Button>
  } else {
    ctcLink = 'Not set';
  }

  let webportalLink;

  if(site.webportal != null) {
    webportalLink = <Button tag="a" href={site.webportal} target="_blank">Webportal</Button>;
  } else {
    webportalLink = 'Not set';
  }

  const phaseName = site.phase.charAt(0).toUpperCase() + site.phase.slice(1);
  const trackerCount = site.trackerCount === 1 ? `${site.trackerCount} tracker` : `${site.trackerCount} trackers`;

  return <DataTableRow ref={domRef} onClick={setRowActive} onMouseOver={handleEnter} onMouseOut={handleLeave} selected={active}>
    <DataTableCell className="state_cell"><StateIndicator state={state} /></DataTableCell>
    <DataTableCell><Button tag={Link} to={`/site/${site.id}`}>{site.name}</Button><SiteNameButton site={site} /></DataTableCell>
    <DataTableCell>{phaseName} <PhaseButton site={site} /></DataTableCell>
    <DataTableCell className="state_cell"><StateIndicator state={trackerState} /></DataTableCell>
    <DataTableCell><Button tag={Link} to={`/site/${site.id}`}>{trackerCount}</Button></DataTableCell>
    <DataTableCell>{ctcLink} <QreaderButton site={site} /></DataTableCell>
    <DataTableCell>{webportalLink} <WebportalButton site={site} /></DataTableCell>
    <DataTableCell>{pendingCount ? `${pendingCount} pending` : '-'}</DataTableCell>
    <DataTableCell className="button_cell"><Button disabled={deleteMutation.isLoading} onClick={remove}><FeatherIcon icon="trash-2" /></Button></DataTableCell>
  </DataTableRow>;
};

export interface SiteListProps {
  sites: SiteOverviewData[] | undefined;
  uploads: UploadSiteData[] | undefined;
  uiState: UiState;
  empty?: boolean;
  error?: boolean;
};

export const SiteList: React.SFC<SiteListProps> = ({ sites, uploads, uiState, empty, error }) => {
  let body: React.ReactNode;

  if(error) {
    body = <DataTableRow>
      <DataTableCell colSpan={99} className="message_row">
        Unable to load site list.
      </DataTableCell>
    </DataTableRow>;
  } else if(sites == null) {
    body = <DataTableRow>
      <DataTableCell colSpan={99} className="message_row">
        Loading ...
      </DataTableCell>
    </DataTableRow>;
  } else if(empty) {
    body = <DataTableRow>
      <DataTableCell colSpan={99} className="message_row">
        No sites created.
      </DataTableCell>
    </DataTableRow>;
  } else if(sites.length === 0) {
    body = <DataTableRow>
      <DataTableCell colSpan={99} className="message_row">
        No sites matched the criteria.
      </DataTableCell>
    </DataTableRow>;
  } else {
    body = sites.map((site) => {
      const siteUpload = uploads?.find(u => u.id === site.id);
      return <SiteRow key={site.id} site={site} pendingCount={siteUpload?.pendingCount} uiState={uiState} />;
    });
  }

  return <>
    <DataTable className="site_list" stickyRows={1}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell></DataTableHeadCell>
            <DataTableHeadCell>Name</DataTableHeadCell>
            <DataTableHeadCell>Phase</DataTableHeadCell>
            <DataTableHeadCell></DataTableHeadCell>
            <DataTableHeadCell>Commission</DataTableHeadCell>
            <DataTableHeadCell>CTC</DataTableHeadCell>
            <DataTableHeadCell>Webportal</DataTableHeadCell>
            <DataTableHeadCell>Uploads</DataTableHeadCell>
            <DataTableHeadCell></DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {body}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  </>;
};
