import { HistoryData, TrackerData } from "../types";

export type TrackerCol = {
  key: string, // object property
  label: string, // web header text
  isNumeric?: boolean;
}

const trackerCols: TrackerCol[] = [
  { key: 'name', label: 'Customer Name', },
  { key: 'backtracking_group', label: 'Backtracking Group', },

  { key: 'controller', label: 'Track.bloxx ID', },
  { key: 'address', label: 'Track.bloxx Address', },
  { key: 'calibration', label: 'Calibration', isNumeric: true,  },

  { key: 'drive', label: 'Drive Unit', }, // version 1 only
  { key: 'first_long', label: 'First Longitude', isNumeric: true,  },
  { key: 'first_lat', label: 'First Latitude', isNumeric: true,  },
  { key: 'first_elevation', label: 'First Elevation', isNumeric: true,  },
  { key: 'long', label: 'Middle Longitude', isNumeric: true,  },
  { key: 'lat', label: 'Middle Latitude', isNumeric: true,  },
  { key: 'elevation', label: 'Middle Elevation', isNumeric: true,  },
  { key: 'last_long', label: 'Last Longitude', isNumeric: true,  },
  { key: 'last_lat', label: 'Last Latitude', isNumeric: true,  },
  { key: 'last_elevation', label: 'Last Elevation', isNumeric: true,  },
  { key: 'group', label: 'Cleaning Group', isNumeric: true, },
  { key: 'cleaning', label: 'Cleaning Position', isNumeric: true, },
  { key: 'gateway', label: 'Communication Group', },
  { key: 'length_tolerance', label: 'Module Size Tracking Tolerance', },
  { key: 'joint_north_long', label: 'Cardan Joint C1 Longitude', },
  { key: 'joint_north_lat', label: 'Cardan Joint C1 Latitude', },
  { key: 'joint_north_elevation', label: 'Cardan Joint C1 Elevation', },
  { key: 'joint_south_long', label: 'Cardan Joint C2 Longitude', },
  { key: 'joint_south_lat', label: 'Cardan Joint C2 Latitude', },
  { key: 'joint_south_elevation', label: 'Cardan Joint C2 Elevation', },
  { key: 'tracking_mode', label: 'Tracking Mode', },
  { key: 'monitoring_group', label: 'Monitoring Group', },

];

const fullTrackerCols: TrackerCol[] = [
  { key: 'id', label: 'Internal Name', },
  ...trackerCols,
];

export const getColKeys = (source: HistoryData[] | undefined): string[] => {
  const base = trackerCols.map(col => col.key);
  if(source == undefined) {
    return base;
  }
  const trackers = source.map( row => row.data?.trackers[0]! );
  const advanced = trackers.map( h => Object.keys(h) ).flat(1);
  const allArr = [...base, ...advanced];
  const all = new Set(allArr);
  return Array.from(all).filter( key => key != 'id' );
}

// if key has label, return label else keep key
export const getColHeaders = (keys: string[]): string[] => {
  return keys.map( key => trackerCols.find(col => col.key === key)?.label ?? key );
}

export const getColCells = (keys: string[]): TrackerCol[] => {
  return keys.map( key => trackerCols.find(col => col.key === key) ?? { key, label: key, } );
}

export const getValue = (key: string, tracker: TrackerData): string | number => {
  return tracker[key] ?? '-';
}