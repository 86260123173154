import * as React from 'react';
import { useEffect, useState } from 'react';
import { SiteData } from '../types';
import { ImportDialogProps, ImportDialogWrapper, ImportRow } from './import_dialog';
import { useUpdateSite } from './backend';

export interface JSONFileDialog extends Omit<ImportDialogProps,'data' | 'allowModeChange'> {
  file: File;
}

export const JSONFileDialog: React.FC<JSONFileDialog> = ({ site, file, onClosed }) => {
  const mutation = useUpdateSite();
  const [ loadedData, setLoadedData ] = useState<SiteData | undefined>(undefined);
  const [ error, setError ] = useState<string | undefined>(undefined);

  useEffect(() => {
    readFile(file);
  }, [file]);

  const readFile = async (file: File) => {
		const reader = new FileReader();
    reader.onloadend = () => {
			try {
        const data = reader.result ?? '';
        const result: string = typeof data === 'string' ? data : Buffer.from(data).toString();
        const json: SiteData = JSON.parse(result);
        if(json['trackers'] == undefined) {
          setError("This file included no trackers.");
        } else {
          setLoadedData(json);
        }
      } catch (err: unknown) {
        let message = "";
        if (err instanceof Error) {
          message = err.message;
        }
        setError("File could not be parsed.\n" + message);
			}
    }
    reader.readAsText(file);
  }

  const doImport = async () => {
    try {
      await mutation.mutateAsync({
        id: site.id,
        version: site.version,
        comment: `Import file '${file.name}'`,
        trackers: loadedData?.trackers,
        parameter: loadedData?.parameter,
        communication: loadedData?.communication,
      });

      onClosed();

    } catch {
      alert("Unable to save changes. Please try reloading if the error persists.");
    }
  };

  const params = loadedData?.parameter;
  const comms = loadedData?.communication?.length;

  return <ImportDialogWrapper disabled={mutation.isLoading} cancel={onClosed} accept={loadedData == undefined ? undefined : doImport}>
    { error == undefined && loadedData == undefined ? 'Loading...' : null }
    { error != undefined && loadedData == undefined ? error : null }
    { error == undefined && loadedData != undefined ? <><ImportRow name="File name">{file.name}</ImportRow>
      <ImportRow name="Entries">{loadedData.trackers?.length ?? 'Not included'}</ImportRow>
      <ImportRow name="Parameter">{params == undefined ? 'Not included' : 'Yes, included'}</ImportRow>
      <ImportRow name="Comms">{comms == undefined || comms == 0 ? 'Not included' : comms}</ImportRow></>
    : null }
  </ImportDialogWrapper>;
}