import * as React from 'react';
import { useState } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { SiteOverviewData } from '../types';

import { FeatherIcon } from './feather_icon';
import { useUpdateSite } from './backend';
import { TextInput } from './form';

export interface SiteNameDialogProps {
  site: SiteOverviewData;
  done: () => void;
}

export const SiteNameDialog: React.FC<SiteNameDialogProps> = ({ site, done }) => {
  const [name, setName] = useState(site.name);
  const mutation = useUpdateSite();

  const accept = async () => {
    try {
      await mutation.mutateAsync({
        id: site.id,
        comment: `Renaming from '${site.name}' to '${name}'`,
        name,
      });

      done();
    } catch {
      alert("Unable to update backend");
    }
  }

  const disabled = mutation.isLoading;

  return <Dialog open={true} onClose={done}>
    <DialogTitle>Change Site Name</DialogTitle>
    <DialogContent>
      <TextInput label="Name" value={name} update={setName} />
    </DialogContent>
    <DialogActions>
      <DialogButton disabled={disabled} action="cancel">Cancel</DialogButton>
      <DialogButton disabled={disabled} isDefaultAction onClick={accept}>Save</DialogButton>
    </DialogActions>
  </Dialog>;
}

export interface SiteNameButtonProps {
  site: SiteOverviewData;
}

export const SiteNameButton: React.FC<SiteNameButtonProps> = ({ site }) => {
  const [visible, setVisible] = useState(false);

  const open = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(true);
  }

  const done = () => {
    setVisible(false);
  }

  const dialog = visible ?  <SiteNameDialog site={site} done={done} /> : null;

  return <>
    {dialog}
    <Button className="edit_button" onClick={open}>
      <FeatherIcon icon="edit-2" />
    </Button>
  </>;
}
