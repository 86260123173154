import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { ThemeProvider } from '@rmwc/theme';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter as Router } from 'react-router-dom';

import Keycloak = require('keycloak-js');

import { App } from './components/app';
import { QueryClient, QueryClientProvider } from 'react-query';

interface MountOptions {
  keycloakConfig: Keycloak.KeycloakConfig;
  apiClient: string;
}

export const mount = (root: HTMLElement, { keycloakConfig, apiClient }: MountOptions) => {
  const keycloakClient = Keycloak(keycloakConfig);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: 60 * 1000,
      },
    },
  })

  ReactDOM.render(<ThemeProvider options={{primary: 'rgb(11, 51, 94)', secondary: 'green'}}>
    <ReactKeycloakProvider autoRefreshToken={true} initOptions={{ onLoad: 'login-required' }} authClient={keycloakClient}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App apiClient={apiClient} />
        </Router>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  </ThemeProvider>, document.getElementById('root'));
};
