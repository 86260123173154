import * as React from 'react';
import { useMemo } from 'react';

import { Radio } from '@rmwc/radio';
import '@rmwc/radio/styles';

import { TrackerDataMap, TrackerData, SelectableStateType, SelectableSitePhase, SiteOverviewData } from '../types';
import { getTrackerState } from '../state_calc';

interface SitePhaseRadioProps {
  label: string;
  count: number;
  type: SelectableSitePhase;
  value: SelectableSitePhase;
  update: (data: SelectableSitePhase) => void;
}

const SitePhaseRadio: React.SFC<SitePhaseRadioProps> = ({ label, count, type, value, update }) => {
  const handleChange = () => {
    update(type);
  };

  return <Radio value={type} checked={value === type} onChange={handleChange}>
    {label} ({count} sites)
  </Radio>;
};

export interface SitePhaseChoiceProps {
  sites: SiteOverviewData[] | undefined;
  value: SelectableSitePhase;
  update: (data: SelectableSitePhase) => void;
};

export const SitePhaseChoice: React.SFC<SitePhaseChoiceProps> = ({ sites, value, update }) => {
  const counts = useMemo(() => {
    const result = {
      planning: 0,
      commission: 0,
      live: 0,
    };

    if(sites == null) {
      return result;
    }

    sites.forEach((site) => {
      const key = site.phase;
      result[key] += 1;
    });

    return result;
  }, [sites]);

  return <div>
    <SitePhaseRadio label="All" count={sites?.length ?? 0} type="all" value={value} update={update} />
    <SitePhaseRadio label="Planning" count={counts.planning} type="planning" value={value} update={update} />
    <SitePhaseRadio label="Commission" count={counts.commission} type="commission" value={value} update={update} />
    <SitePhaseRadio label="Live" count={counts.live} type="live" value={value} update={update} />
  </div>
};

