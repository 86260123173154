import * as React from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { useSite, useUpdateSite } from './backend';
import { SiteHeader } from './site_header';
import { TextArea } from './form';

interface ContentViewProps {
  id: string;
  data: string | undefined;
}

const ContentView: React.FC<ContentViewProps> = ({ id, data }) => {
  const [comment, setComment] = React.useState(data);
  const mutation = useUpdateSite();

  const accept = async () => {
    try {
      await mutation.mutateAsync({
        id: id,
        comment: `Setting comment`,
        siteComment: comment,
      });
    } catch {
      alert("Unable to update backend");
    }
  };

  return <>
    <div>
      <TextArea value={comment} update={setComment} rows={8} cols={80} className="input_text" label="Comment" placeholder="Add your comment here..." fullwidth={false} />
    </div>
    <br/>
    <div>
      <Button onClick={accept} raised>Save</Button>
    </div>
  </>;
}

const NoSite: React.FC = () => {
  return <div className="state_message">No Site available.</div>
}

interface SiteCommViewProps {
  id: string;
}

export const SiteCommentView: React.FC<SiteCommViewProps> = ({ id }) => {
  const siteQuery = useSite(id);
  const site = siteQuery.data;

  let content;
  if(site == null) {
    content = <NoSite />;
  } else {
    content = <ContentView id={id} data={site.siteComment} />;
  }

  return <div className="site">
    <div className="header">
      <SiteHeader site={site} />
    </div>
    <div className="comment_content">
      {content}
    </div>
  </div>;
}
