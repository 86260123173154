import * as React from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import { Select } from '@rmwc/select';
import '@rmwc/select/styles';
import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import * as XLSX from 'xlsx';

import { matchBoxes } from '../api';
import { serialToAddress } from '../import';
import { useState, useMemo, useCallback } from 'react';
import { usePromise } from './helper';
import { useKeycloak } from '@react-keycloak/web';

interface MappingEntry {
  input: string;
  box?: string;
  controller?: string;
  address?: string;
};

export const addressToSerial = (address: string): string => {
  const swapped = address.match(/.{2}/g)!.reverse().join('');

  const raw = parseInt(swapped, 16);

  const fa = raw & 0x3ffff;
  const sn = raw >> 18;

  return String(fa * 1000000 + sn);
};

const convertBoxToSerial = async (data: Array<MappingEntry>, token: string): Promise<Array<MappingEntry>> => {
  const boxes = data.filter((line) => line.box != null).map((line) => line.box!);

  if(boxes.length == 0) {
    return data;
  }

  const matched = await matchBoxes(boxes, token!);

  return data.map((line) => {
    if(line.box != null && line.controller == null) {
      const controller = matched[line.box];
      return { ...line, controller };
    } else {
      return line;
    }
  })
};

const convertSerialToAddress = (data: MappingEntry[]): MappingEntry[] => {
  return data.map((line) => {
    if(line.controller != null && line.address == null) {
      const address = serialToAddress(line.controller);

      if(address != null) {
        return { ...line, address }
      } else {
        return line;
      }
    } else {
      return line;
    }
  });
};

const convertAddressToSerial = (data: MappingEntry[]): MappingEntry[] => {
  return data.map((line) => {
    if(line.address != null && line.controller == null) {
      const controller = addressToSerial(line.address);
      return { ...line, controller }
    } else {
      return line;
    }
  });
};


const matchInput = (data: MappingEntry[]): MappingEntry[] => {
  return data.map((line) => {
    const { input } = line;

    if(input.match(/^\d{3}\-\d+$/)) {
      return {
        ...line,
        box: input,
      };
    } else if(input.match(/^\d{11}$/)) {
      return {
        ...line,
        controller: input,
      };
    } else if(input.match(/^[a-zA-Z0-9]{8}$/)) {
      return {
        ...line,
        address: input,
      };
    } else {
      return line;
    }
  });
};

const exportAddresses = (data: MappingEntry[] | undefined, extension: string='csv') => {
  const result = [];

  result.push(["Input", "Box", "Controller", "Address"]);

  if(data != null) {
    for(const line of data) {
      result.push([
        line.input,
        line.box,
        line.controller,
        line.address,
      ]);
    }
  }

  var ws = XLSX.utils.aoa_to_sheet(result);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Track.bloxx addresses');
  XLSX.writeFile(wb, `address_map.${extension}`);
};

export const useConvertedInput = (input: string, token: string | undefined) => {
  const promise = useMemo(async () => {
    if(token == null) {
      throw new Error("No token found");
    }

    const steps = [
      matchInput,
      convertBoxToSerial,
      convertSerialToAddress,
      convertAddressToSerial,
    ];

    let entries: MappingEntry[] = input.split(/\s+/)
      .filter((str) => str != '')
      .map((input) => { return { input }; })

    for(const fun of steps) {
      entries = await fun(entries, token);
    }

    return entries;
  }, [input]);

  return usePromise(promise);
};

interface ExportMapProps {
  data: MappingEntry[] | undefined;
};

const ExportMap: React.SFC<ExportMapProps> = ({ data }) => {
  const [extension, setExtension] = useState("xlsx");

  const doExport = useCallback(() => {
    exportAddresses(data, extension);
  }, [data, extension]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setExtension(e.target.value);
  }, []);

  return <div className="export">
    <Select className="extension" label="Extension" enhanced value={extension} onChange={handleChange} options={['csv', 'xlsx', 'odf']} />
    <Button raised onClick={doExport}>Export</Button>
  </div>
};

export const CalculateAddress: React.SFC = () => {
  const { keycloak } = useKeycloak();
  const [input, setInput] = useState('');
  const [matched, loading] = useConvertedInput(input, keycloak.token);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  }, []);

  let rows;

  if(loading) {
    rows = <DataTableRow>
      <DataTableCell colSpan={4}>Loading ...</DataTableCell>
    </DataTableRow>;
  } else if(matched == null || matched.length == 0) {
    rows = <DataTableRow>
      <DataTableCell colSpan={4}>No input given ...</DataTableCell>
    </DataTableRow>;
  } else {
    rows = matched.map((entry, index) => {
      return <DataTableRow key={index}>
        <DataTableCell>{entry.input ?? '-'}</DataTableCell>
        <DataTableCell>{entry.box ?? '-'}</DataTableCell>
        <DataTableCell>{entry.controller ?? '-'}</DataTableCell>
        <DataTableCell>{entry.address ?? '-'}</DataTableCell>
      </DataTableRow>;
    });
  }

  return <div className="calculate_address">
    <TextField className="input" textarea outlined onChange={handleChange} value={input} />
    <ExportMap data={matched} />
    <DataTable className="result">
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell>Input</DataTableHeadCell>
            <DataTableHeadCell>Box</DataTableHeadCell>
            <DataTableHeadCell>Controller</DataTableHeadCell>
            <DataTableHeadCell>Address</DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {rows}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  </div>
};

