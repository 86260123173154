import * as React from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';
import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { TrackerData } from '../types';
import { getPoleState, getTrackerState, getJointState } from '../state_calc';
import { StateIndicator } from './state_indicator';
import { InteractiveRow } from './site_row_wrapper';
import { UiState } from './ui_state';
import { FeatherIcon } from './feather_icon';

interface TrackerListRowProps {
  tracker: TrackerData;
}

const TrackerListRow: React.FC<TrackerListRowProps> = ({ tracker }) => {
  const state = getTrackerState(tracker);
  const poles = React.useMemo(() => getPoleState(tracker), [tracker]);
  const joints = React.useMemo(() => getJointState(tracker), [tracker]);

  return <>
    <DataTableCell className="state_cell"><StateIndicator state={state} /></DataTableCell>
    <DataTableCell>{tracker.id}</DataTableCell>
    <DataTableCell>{tracker.name ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.controller ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.address ?? '-'}</DataTableCell>
    <DataTableCell isNumeric>{tracker.calibration ?? '-'}</DataTableCell>
    <DataTableCell isNumeric>{tracker.elevation ?? '-'}</DataTableCell>
    <DataTableCell isNumeric>{tracker.group ?? '-'}</DataTableCell>
    <DataTableCell isNumeric>{tracker.cleaning ?? '-'}</DataTableCell>
    <DataTableCell>{poles}{joints > 0 ? ` + ${joints}` : ''}</DataTableCell>
    <DataTableCell>{tracker.backtracking_group ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.gateway ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.monitoring_group ?? '-'}</DataTableCell>
    <DataTableCell>{tracker.tracking_mode ?? '-'}</DataTableCell>
  </>;
}

interface TrackerRowProps {
  siteId: string | undefined;
  tracker: TrackerData;
  uiState: UiState;
}

const TrackerRow: React.FC<TrackerRowProps> = memo(({ siteId, tracker, uiState }) => {
  return <InteractiveRow id={tracker.id} uiState={uiState}>
    <TrackerListRow tracker={tracker} />
    <DataTableCell className="button_cell"><Button tag={Link} to={`/site/${siteId}/tracker/${tracker?.id}/history`} title="Go to History"><FeatherIcon icon="clock" /></Button></DataTableCell>
  </InteractiveRow>;
});

const TrackerListHeader: React.FC = () => {
  return <>
    <DataTableHeadCell></DataTableHeadCell>
    <DataTableHeadCell className="table_id">Internal Name</DataTableHeadCell>
    <DataTableHeadCell className="table_tracker">Customer Name</DataTableHeadCell>
    <DataTableHeadCell className="table_controller">Track.bloxx ID</DataTableHeadCell>
    <DataTableHeadCell className="table_controller">Track.bloxx Address</DataTableHeadCell>
    <DataTableHeadCell className="table_calibration">Calibration</DataTableHeadCell>
    <DataTableHeadCell className="table_elevation">Elevation</DataTableHeadCell>
    <DataTableHeadCell className="table_cleaning">Cleaning Group</DataTableHeadCell>
    <DataTableHeadCell className="table_cleaning">Cleaning Position</DataTableHeadCell>
    <DataTableHeadCell className="table_monitor">Poles +Joints</DataTableHeadCell>
    <DataTableHeadCell className="table_group">Backtracking Group</DataTableHeadCell>
    <DataTableHeadCell className="table_comm">Communication Group</DataTableHeadCell>
    <DataTableHeadCell className="table_comm">Monitoring Group</DataTableHeadCell>
    <DataTableHeadCell className="table_comm">Tracking Mode</DataTableHeadCell>
    <DataTableHeadCell className="table_action"></DataTableHeadCell>
  </>;
}

export interface TrackerListProps {
  siteId: string | undefined;
  trackers: TrackerData[] | undefined;
  empty?: boolean;
  uiState: UiState;
}

export const TrackerList: React.FC<TrackerListProps> = memo(({ siteId, trackers, empty, uiState }) => {
  let body: React.ReactNode;

  if(trackers == null) {
    body = <DataTableRow>
      <DataTableCell colSpan={99} className="message_row">
        Loading ...
      </DataTableCell>
    </DataTableRow>;
  } else if(empty) {
    body = <DataTableRow>
      <DataTableCell colSpan={99} className="message_row">
        There are no trackers in this project, yet.
      </DataTableCell>
    </DataTableRow>;
  } else if(trackers.length === 0) {
    body = <DataTableRow>
      <DataTableCell colSpan={99} className="message_row">
        No trackers matched the criteria.
      </DataTableCell>
    </DataTableRow>;
  } else {
    body = trackers.map((data) => {
      return <TrackerRow key={data.id} siteId={siteId} tracker={data} uiState={uiState} />;
    });
  }

  return <>
    <DataTable stickyRows={1}>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            <TrackerListHeader />
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {body}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  </>;
});
