import * as React from 'react';
import { useState, useMemo, useEffect } from 'react';
import Emittery = require('emittery');

type UiStateValue = string | undefined;

export interface UiStateEvents {
  activeChanged: string | undefined;
  hoveredChanged: string | undefined;
}

export class UiState extends Emittery<UiStateEvents> {
  active: UiStateValue = undefined;
  hovered: UiStateValue = undefined;

  constructor() {
    super();
  }

  setActive(tracker: string | undefined) {
    this.active = tracker;
    this.emit('activeChanged', this.active);
  }

  setHovered(tracker: string | undefined) {
    this.hovered = tracker;
    this.emit('hoveredChanged', this.hovered);
  }

  isHovered(id: string) {
    return this.hovered === id;
  }

  clearHovered(id: string) {
    if(this.hovered === id) {
      this.setHovered(undefined);
    }
  }

  toggleHovered(id: string) {
    this.setHovered(this.hovered === id ? undefined : id);
  }

  isActive(id: string) {
    return this.active === id;
  }

  clearActive(id: string) {
    if(this.active === id) {
      this.setActive(undefined);
    }
  }

  toggleActive(id: string) {
    this.setActive(this.active === id ? undefined : id);
  }
};

export const useIsActive = (tracker: string, uiState: UiState) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(uiState.isActive(tracker));

    const update = (nowActive: string | undefined) => {
      setActive(nowActive === tracker);
    };

    uiState.on('activeChanged', update);

    return () => {
      uiState.off('activeChanged', update);
    };
  }, [tracker, uiState]);

  return active;
};

export const useIsHovered = (tracker: string, uiState: UiState) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setHovered(uiState.isHovered(tracker));

    const update = (nowActive: string | undefined) => {
      setHovered(nowActive === tracker);
    };

    uiState.on('hoveredChanged', update);

    return () => {
      uiState.off('hoveredChanged', update);
    };
  }, [tracker, uiState]);

  return hovered;
};

export const useUiState = () => {
  return useMemo(() => {
    return new UiState();
  }, []);
};

