import * as React from 'react';
import { useEffect, useRef, memo } from 'react';

import { DataTableRow } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { UiState, useIsActive } from './ui_state';

interface InteractiveRowProps {
  id: string;
  uiState: UiState;
}

export const InteractiveRow: React.FC<InteractiveRowProps> = memo(({ id, uiState, children }) => {
  const domRef = useRef<HTMLElement>(null);
  const active = useIsActive(id, uiState);

  useEffect(() => {
    const element = domRef.current;

    if(!active || element == null) {
      return;
    }

    const parent = element.closest('div')!;
    const head = parent.querySelector('th')!;

    if(active && element) {
      const over = parent.scrollTop + head.clientHeight >= element.offsetTop;
      const under = parent.scrollTop + parent.clientHeight <= element.offsetTop + element.clientHeight;

      if(over || under) {
        const y = element.offsetTop + element.clientHeight / 2 - parent.clientHeight / 2;

        parent.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [active]);

  const setRowActive = () => {
    uiState.toggleActive(id);
  }

  const handleEnter = () => {
    uiState.setHovered(id);
  }

  const handleLeave = () => {
    uiState.clearHovered(id);
  }

  return <DataTableRow ref={domRef} onClick={setRowActive} onMouseOver={handleEnter} onMouseOut={handleLeave} selected={uiState.active === id}>
    {children}
  </DataTableRow>;
});