import * as React from 'react';
import { useState } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { SiteData } from '../types';
import { exportTrackers } from '../export';

interface ExportDialogProps {
  site: SiteData;
  onClosed: () => void;
};

const ExportDialog: React.FC<ExportDialogProps> = ({ site, onClosed }) => {
  const [fileType, setFileType] = useState('xlsx');

  const doExport = () => {
    exportTrackers(site, site.name, fileType);
    onClosed();
  };

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setFileType(e.target.value)
  };

  return (
    <Dialog open={true} onClosed={onClosed}>
      <DialogTitle>Export Tracker Information</DialogTitle>
      <DialogContent>
        <Select label="File type" value={fileType} onChange={handleChange}>
          <option value="xlsx">Excel</option>
          <option value="csv">CSV</option>
          <option value="json">JSON</option>
        </Select>
      </DialogContent>
      <DialogActions>
        <DialogButton action="cancel" onClick={onClosed}>Cancel</DialogButton>
        <DialogButton isDefaultAction onClick={doExport}>Export</DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export interface ExportButtonProps {
  site: SiteData | undefined;
};

export const ExportButton: React.SFC<ExportButtonProps> = ({ site }) => {
  const [isVisible, setIsVisible] = useState(false);

  let dialog = (site != null && isVisible) ? <ExportDialog site={site} onClosed={() => setIsVisible(false)} /> : null;

  return <>
    {dialog}
    <Button disabled={site == null} className="margin_top" raised onClick={() => setIsVisible(true)}>Export List</Button>
  </>;
};
