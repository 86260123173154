import * as React from 'react';
import { ChangeEvent } from 'react';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

import { SitePhase } from '../types';

export interface PhaseSelectProps extends React.HTMLProps<'select'> {
  value: SitePhase;
  update: (data: SitePhase) => void;
};

export const PhaseSelect: React.FC<PhaseSelectProps> = ({ value, update, ref, ...other }) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    update(e.target.value as SitePhase);
  };

  const options = [
    {
      label: 'Planning',
      value: 'planning',
    },
    {
      label: 'Commission',
      value: 'commission',
    },
    {
      label: 'Live',
      value: 'live',
    },
  ]

  return <Select {...other} value={value} onChange={handleChange} options={options} />
};
