import * as React from 'react';
import { memo } from 'react';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { HistoryData } from '../types';

interface DeviceRowProps {
  history: HistoryData;
}

const DataRow: React.FC<DeviceRowProps> = memo(({ history }) => {
  return <DataTableRow>
    <DataTableCell>{new Date(history.date).toLocaleString()}</DataTableCell>
    <DataTableCell>{history.comment}</DataTableCell>
    <DataTableCell>{history.userName ?? 'Unknown'}</DataTableCell>
  </DataTableRow>;
})

const MessageRow: React.FC<{ message: string }> = ({ message }) => {
  return <DataTableRow>
    <DataTableCell colSpan={99} className="message_row">
      {message}
    </DataTableCell>
  </DataTableRow>;
}

interface ListProps {
  data: HistoryData[] | undefined;
}

// newest first
const sortByDate = (h1: HistoryData, h2: HistoryData) => {
  return new Date(h2.date).getTime() - new Date(h1.date).getTime();
}

export const SiteHistoryList: React.FC<ListProps> = memo(({ data }) => {
  const state = React.useMemo(() => data?.sort(sortByDate), [data]);

  let body;
  if(state == null) {
    body = <MessageRow message='Loading ...' />;
  } else if(state.length == 0) {
    body = <MessageRow message='There are no entries in this project, yet.' />;
  } else {
    body = state.map((entry) => {
      return <DataRow key={entry.id} history={entry} />;
    });
  }

  return <DataTable stickyRows={1}>
    <DataTableContent>
      <DataTableHead>
        <DataTableRow>
          <DataTableHeadCell>Date</DataTableHeadCell>
          <DataTableHeadCell>Comment</DataTableHeadCell>
          <DataTableHeadCell>User</DataTableHeadCell>
        </DataTableRow>
      </DataTableHead>
      <DataTableBody>
        {body}
      </DataTableBody>
    </DataTableContent>
  </DataTable>;
});
