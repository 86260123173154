import * as React from 'react';
import { memo } from 'react';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';

import { HistoryData, TrackerData } from '../types';
import { TrackerCol, getColCells, getColHeaders, getColKeys, getValue } from './tracker_cols';

type DiffEntry = { entry: HistoryData, diff: string[] };

interface DeviceRowProps {
  entry: DiffEntry;
  cols: TrackerCol[];
}

const DataRow: React.FC<DeviceRowProps> = memo(({ entry, cols }) => {
  const history = entry.entry;
  const tracker = history.data?.trackers[0]!;

  const cells = cols.map( col => {
    const css = entry.diff.indexOf(col.key) != -1 ? "cell_marked" : "cell_normal";
    return <DataTableCell key={col.key} isNumeric={col.isNumeric}>
      <span className={css}>
        {getValue(col.key, tracker)}
      </span>
    </DataTableCell>;
  });

  return <DataTableRow>
    <DataTableCell>{new Date(history.date).toLocaleString()}</DataTableCell>
    <DataTableCell>{history.comment}</DataTableCell>
    <DataTableCell>{tracker.id}</DataTableCell>
    <DataTableCell>{history.userName ?? 'Unknown'}</DataTableCell>
    {cells}
  </DataTableRow>;
})

const MessageRow: React.FC<{ message: string }> = ({ message }) => {
  return <DataTableRow>
    <DataTableCell colSpan={99} className="message_row">
      {message}
    </DataTableCell>
  </DataTableRow>;
}

interface BodyProps {
  data: DiffEntry[] | undefined;
  cols: TrackerCol[];
}

const ListBody: React.FC<BodyProps> = memo(({ data, cols }) => {
  let body;

  if(data == null) {
    body = <MessageRow message='Loading ...' />;
  } else if(data.length == 0) {
    body = <MessageRow message='There are no entries in this project, yet.' />;
  } else {
    body = data.map((entry) => {
      return <DataRow key={entry.entry.id} entry={entry} cols={cols} />;
    });
  }

  return <DataTableBody>
    {body}
  </DataTableBody>;
});

interface ListHeaderProps {
  cols: string[];
}

const ListHeader: React.FC<ListHeaderProps> = ({ cols }) => {
  const headers = cols.map( col => {
    return <DataTableHeadCell key={col}>{col}</DataTableHeadCell>
  });

  return <DataTableHead>
    <DataTableRow>
      <DataTableHeadCell>Date</DataTableHeadCell>
      <DataTableHeadCell>Comment</DataTableHeadCell>
      <DataTableHeadCell>Tracker</DataTableHeadCell>
      <DataTableHeadCell>User</DataTableHeadCell>
      {headers}
    </DataTableRow>
  </DataTableHead>;
}

interface ListProps {
  data: HistoryData[] | undefined;
}

// oldest first
const sortASCByDate = (h1: HistoryData, h2: HistoryData) => {
  return new Date(h1.date).getTime() - new Date(h2.date).getTime();
}

// newest first
const sortDescByDate = (h1: DiffEntry, h2: DiffEntry) => {
  return new Date(h2.entry.date).getTime() - new Date(h1.entry.date).getTime();
}

const getDiff = (t1: TrackerData, t2: TrackerData): string[] => {
  const diff = [];
  for(let k in t2) {
    if(t1[k] != t2[k]) {
      diff.push(k);
    }
  }

  for(let k in t1) {
      if(!t2.hasOwnProperty(k)) {
        diff.push(k);
      }
  }

  return diff;
}

const importHistory = (data: HistoryData[] | undefined): DiffEntry[] | undefined => {
  if(data == undefined || data.length == 0) {
    return undefined;
  }

  const sorted = data.sort(sortASCByDate);
  const entries: DiffEntry[] = [{ entry: sorted[0], diff: [], }];

  const [initial, ...other] = sorted;
  let before = initial;
  for(const entry of other) {
    const beforeTracker = before.data?.trackers[0]!;
    const afterTracker = entry.data?.trackers[0]!;
    const diff = getDiff(beforeTracker, afterTracker);
    if(diff.length != 0) {
      entries.push({entry, diff});
      before = entry;
    }
  }

  return entries.sort(sortDescByDate);
}

export const TrackerHistoryList: React.FC<ListProps> = memo(({ data }) => {
  const state = React.useMemo(() => importHistory(data), [data]);
  const keys = getColKeys(data);
  const headers = getColHeaders(keys);
  const cells = getColCells(keys);

  return <DataTable stickyRows={1}>
    <DataTableContent>
      <ListHeader cols={headers} />
      <ListBody data={state} cols={cells} />
    </DataTableContent>
  </DataTable>;
});
