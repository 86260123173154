import * as React from 'react';
import { memo } from 'react';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import '@rmwc/data-table/styles';
import { FeatherIcon } from './feather_icon';

import { CommData } from '../types';
import { UiState } from './ui_state';
import { InteractiveRow } from './site_row_wrapper';

type RowData = CommData & { real_tracker_count: number };

interface DeviceRowProps {
  device: RowData;
  uiState: UiState;
}

const DeviceRow: React.FC<DeviceRowProps> = memo(({ device, uiState }) => {
  const realCount = device.real_tracker_count;
  const dataCount = Number(device.tracker_count ?? 0);
  const displayCount = device.tracker_count ?? '-';
  const warningHint = <>
    {displayCount}
    <span title={`There are ${realCount} trackers matched to this device, but imported count was ${dataCount}.`}>
      <FeatherIcon icon="alert-triangle" className="count_warning" />
    </span>
  </>;

  return <InteractiveRow id={device.id} uiState={uiState}>
    <DataTableCell>{device.name}</DataTableCell>
    <DataTableCell>{device.nr ?? '-'}</DataTableCell>
    <DataTableCell>{device.id ?? '-'}</DataTableCell>
    <DataTableCell>{device.address_qreader ?? '-'}</DataTableCell>
    <DataTableCell>{device.address_ipc ?? '-'}</DataTableCell>
    <DataTableCell>{device.address_switch ?? '-'}</DataTableCell>
    <DataTableCell>{device.sensor_1 ?? '-'}</DataTableCell>
    <DataTableCell>{device.sensor_2 ?? '-'}</DataTableCell>
    <DataTableCell>{device.sensor_3 ?? '-'}</DataTableCell>
    <DataTableCell>{device.antenna ?? '-'}</DataTableCell>
    <DataTableCell>{device.pole ?? '-'}</DataTableCell>
    <DataTableCell isNumeric><span className="count_value">{realCount === dataCount ? displayCount : warningHint}</span></DataTableCell>
  </InteractiveRow>;
})

const MessageRow: React.FC<{ message: string }> = ({ message }) => {
  return <DataTableRow>
    <DataTableCell colSpan={99}>
      {message}
    </DataTableCell>
  </DataTableRow>;
}

const CommListBody: React.FC<CommListProps> = memo(({ data, uiState, commSummary }) => {
  let body;

  if(data == null) {
    body = <MessageRow message='Loading ...' />;
  } else if(data.length == 0) {
    body = <MessageRow message='There are no devices in this project, yet.' />;
  } else {
    body = data.map((device) => {
      const count = commSummary?.get(device.id);
      const rowData:RowData = { ...device,  real_tracker_count: count ?? 0, };
      return <DeviceRow key={device.id} device={rowData} uiState={uiState} />;
    });
  }

  return <DataTableBody>
    {body}
  </DataTableBody>;
});

const CommListHeader: React.FC = () => {
  return <DataTableHead>
    <DataTableRow>
      <DataTableHeadCell className="table_name">Device Name</DataTableHeadCell>
      <DataTableHeadCell className="table_nr">Device Nr</DataTableHeadCell>
      <DataTableHeadCell className="table_id">Name comm. layout</DataTableHeadCell>
      <DataTableHeadCell className="table_ip_qreader">IP Address Q.Reader</DataTableHeadCell>
      <DataTableHeadCell className="table_ip_ipc">IP Address IPC</DataTableHeadCell>
      <DataTableHeadCell className="table_ip_ipc">IP Address Switch</DataTableHeadCell>
      <DataTableHeadCell className="table_connected_1">Sensor 1</DataTableHeadCell>
      <DataTableHeadCell className="table_connected_2">Sensor 2</DataTableHeadCell>
      <DataTableHeadCell className="table_connected_3">Sensor 3</DataTableHeadCell>
      <DataTableHeadCell className="table_version">Antenna Version</DataTableHeadCell>
      <DataTableHeadCell className="table_support">Installation Support</DataTableHeadCell>
      <DataTableHeadCell className="table_amount">Amount Track.bloxx</DataTableHeadCell>
    </DataTableRow>
  </DataTableHead>;
}

interface CommListProps {
  data: CommData[] | undefined;
  uiState: UiState;
  commSummary: Map<string | number, number> | undefined;
}

export const CommList: React.FC<CommListProps> = memo(({ data, uiState, commSummary }) => {
  return <DataTable stickyRows={1}>
    <DataTableContent>
      <CommListHeader />
      <CommListBody data={data} uiState={uiState} commSummary={commSummary} />
    </DataTableContent>
  </DataTable>;
});
