import * as React from 'react';
import { useState } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import '@rmwc/dialog/styles';

import { FeatherIcon } from './feather_icon';

import { TextInput } from './form';
import { SiteOverviewData } from '../types';
import { useUpdateSite } from './backend';

export interface QreaderDialogProps {
  site: SiteOverviewData;
  done: () => void;
};

export const QreaderDialog: React.FC<QreaderDialogProps> = ({ site, done }) => {
  const [qreader, setQreader] = useState(site.qreader);
  const mutation = useUpdateSite();

  const accept = async () => {
    try {
      await mutation.mutateAsync({
        id: site.id,
        comment: `Setting CTC id`,
        qreader,
      });

      done();
    } catch {
      alert("Unable to update backend");
    }
  };

  const disabled = mutation.isLoading;

  return <Dialog open={true} onClose={done}>
    <DialogTitle>Change CTC ID</DialogTitle>
    <DialogContent>
      <TextInput disabled={disabled} label="CTC Q.reader ID" value={qreader} update={setQreader} />
    </DialogContent>
    <DialogActions>
      <DialogButton disabled={disabled} action="cancel">Cancel</DialogButton>
      <DialogButton disabled={disabled} isDefaultAction onClick={accept}>Save</DialogButton>
    </DialogActions>
  </Dialog>;
};

export interface QreaderButtonProps {
  site: SiteOverviewData;
}

export const QreaderButton: React.FC<QreaderButtonProps> = ({ site }) => {
  const [visible, setVisible] = useState(false);

  const open = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(true);
  };

  const done = () => {
    setVisible(false);
  };

  const dialog = visible ? <QreaderDialog site={site} done={done} /> : null;

  return <>
    {dialog}
    <Button className="edit_button" onClick={open}>
      <FeatherIcon icon="edit-2" />
    </Button>
  </>;
};
