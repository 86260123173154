import * as React from 'react';

import { Link } from 'react-router-dom';

export const GlobalHeader: React.FC = ({}) => {
  return <div className="global_header">
    <h1>
      <Link to="/"> Track.bloxx Commissioning </Link>
      <img src="/img/pvtracker_logo.png" />
    </h1>
  </div>
}

<div></div>
