import * as React from 'react';
import { useSite } from './backend';
import { ParameterData } from '../types';
import { allParameterViews } from '../file_types';
import { SiteHeader } from './site_header';

interface Parameter {
  label: string,
  key: string,
  revertValue?: (value: string | number | null) => string | number | null,
}

interface ParamsViewProps {
  data: ParameterData;
  heading: string;
  parameter: Parameter[];
}

const ParamsView: React.FC<ParamsViewProps> = ({ data, heading, parameter }) => {
  const rows = parameter.map( entry => {
    const value = data[entry.key];
    let displayVal;
    if(entry.revertValue) {
      displayVal = value == undefined ? '-' : entry.revertValue(value);
    } else {
      displayVal = value == undefined ? '-' : String(value);
    }
    return <div key={entry.label} className="pair">
      <div className="label">{entry.label}</div>
      <div className="value">{displayVal}</div>
    </div>
  });

  return <div className="param_section">
    <h3>{heading}</h3>
    {rows}
  </div>;
}

const NoParameter: React.FC = () => {
  return <div className="state_message">No Parameter available.</div>
}

const NoSite: React.FC = () => {
  return <div className="state_message">No Site available.</div>
}

export interface SiteParamsViewProps {
  id: string;
}

export const SiteParamsView: React.FC<SiteParamsViewProps> = ({ id }) => {
  const siteQuery = useSite(id);
  const site = siteQuery.data;

  let content;
  if(site == null) {
    content = <NoSite />;
  } else if(site?.parameter == null || Object.keys(site?.parameter).length == 0) {
    content = <NoParameter />;
  } else {
    const firstCol = allParameterViews.slice(0, 3).map(([heading, cells]) => {
      return <ParamsView key={heading} data={site.parameter!} heading={heading} parameter={cells} />;
    });
    const secondCol = allParameterViews.slice(3, allParameterViews.length).map(([heading, cells]) => {
      return <ParamsView key={heading} data={site.parameter!} heading={heading} parameter={cells} />;
    });
    content = <>
      <div className="param_col">
        {firstCol}
      </div>
      <div className="param_col">
        {secondCol}
      </div>
    </>;
  }

  return <div className="site">
    <div className="header">
      <SiteHeader site={site} />
    </div>
    <div className="parameter_content">
      {content}
    </div>
  </div>;
}
