import * as React from 'react';
import { useState, ChangeEvent, useRef, useMemo } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { SiteData, UploadOverviewData } from '../types';
import { useSiteUploads } from './backend';
import { UploadsDialog } from './uploads_dialog';

export interface UploadsButtonProps {
  id: string;
  site: SiteData | undefined;
};

export const UploadsButton: React.SFC<UploadsButtonProps> = ({ id, site }) => {
  const [isOpen, setIsOpen] = useState(false);

  const uploadsQuery = useSiteUploads(id);
  const uploads = uploadsQuery.data;

  const pendingCount = useMemo(() => {
    if(uploads == null) {
      return 0;
    }

    return uploads.reduce((count, upload) => upload.pending ? count + 1 : count, 0)
  }, [uploads]);

  const pending = pendingCount > 0 ? <>[{pendingCount}]</> : null;

  return <>
    {(isOpen && site != null) ? <UploadsDialog id={id} site={site} onClosed={() => setIsOpen(false)} /> : null}
    <Button disabled={site == null} className="margin_top" raised onClick={() => setIsOpen(true)}>
      View Upload {pending}
    </Button>
  </>;
};
