import * as React from 'react';
import { useTrackerHistory } from './backend';
import { SiteHeaderWrapper } from './site_header';
import { TrackerHistoryList } from './tracker_history_list';

export interface HistoryViewProps {
  siteId: string;
  trackerId: string;
}

const NoParameter: React.FC = () => {
  return <div className="state_message">No History Information available.</div>
}

export const TrackerHistoryView: React.FC<HistoryViewProps> = ({ siteId, trackerId }) => {
  const query = useTrackerHistory(siteId, trackerId);
  const history = query.data;

  let content;
  if(history == null) {
    content = <NoParameter />;
  } else {
    content = <TrackerHistoryList data={history} />;
  }

  return <div className="site history">
    <div className="header">
      <SiteHeaderWrapper id={siteId} />
    </div>
    <div className="content">
      <div className="list">
        {content}
      </div>
    </div>
  </div>;
}
