import * as React from 'react';
import { useMemo } from 'react';
import { findMapping } from '../file_mapping';
import { loadSheet, loadSheetDeltas, loadParameter, loadCommunication } from '../import';
import { ImportDialog, ImportDialogProps, ImportRow } from './import_dialog';
import { delay } from './helper';

export interface FileDialogProps extends Omit<ImportDialogProps,'data'> {
  file: File;
}

export const FileDialog: React.FC<FileDialogProps> = ({ file, ...other }) => {
  const data = useMemo(async () => {
    // hacky way to make the dialog actually appear while loading
    await delay(200);

    const reader = await loadSheet(file);
    const mapping = findMapping(reader);

    if(mapping == null) {
      throw new Error("Unable to find file mapping");
    }

    const { sheets, fileType } = mapping;

    if(sheets.length == 0) {
      throw new Error("Unable to find sheet mapping");
    }

    const sheet = sheets[0];
    const parameter = sheets.length >= 2 ? sheets[1] : undefined;
    const comms = sheets.length >= 3 ? sheets[2] : undefined;

    return {
      deltas: loadSheetDeltas(sheet.sheet, sheet.mapping),
      general: loadParameter(parameter?.sheet, parameter?.mapping),
      comms: loadCommunication(comms?.sheet, comms?.mapping),
      comment: `Import file '${file.name}'`,
      merge: fileType.merge,
    };
  }, [file]);

  return (
    <ImportDialog data={data} {...other}>
      <ImportRow name="File name">{file.name}</ImportRow>
    </ImportDialog>
  );
}
