import { useState, useEffect } from "react";

export function usePromise<T>(promise: Promise<T> | undefined): [T | undefined, boolean] {
  const [result, setResult] = useState<T>();
  const [resolving, setResolving] = useState(true);

  useEffect(() => {
    setResult(undefined);

    if(promise == null) {
      setResolving(false);
      return;
    }

    setResolving(true);

    let cancelled = false;

    promise.then((data) => {
      if(cancelled) {
        return;
      }

      setResult(data);
      setResolving(false);
    });

    return () => {
      cancelled = true;
    };
  }, [promise]);

  return [result, resolving];
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

