import { FileMapping, SheetCell, SheetColumns, SheetMapping } from './types';
import { SheetReader, SheetView } from './sheet_reader';
import { fileTypes } from './file_types';

const columnsMatch = (columns: SheetColumns, actualColumns: Array<string>) => {
  if(columns.length > actualColumns.length) {
    return false;
  }

  for(let i = 0; i < columns.length; ++i) {
    const mappedColumn = columns[i][0];
    const actualColumn = actualColumns[i];

    if(mappedColumn instanceof RegExp) {
      if(!actualColumn.match(mappedColumn)) {
        // console.log('mismatch', mappedColumn, actualColumn);
        return false;
      }
    } else if(mappedColumn == null) {
      // TODO
    } else {
      if(mappedColumn != actualColumn) {
        // console.log('mismatch', mappedColumn, actualColumn);
        return false;
      }
    }
  }

  return true;
}

const cellsMatch = (cells: SheetCell[], sheet: SheetView) => {
  for(const cell of cells) {
    const content = sheet.getCell(cell.col, cell.row);
    if(content == null) {
      return false;
    } else if(content != cell.label) {
      return false;
    }
  }

  return true;
}

type SheetMappingResult = { mapping: SheetMapping, sheet: SheetView }[];

export interface FileMappingResult {
  sheets: SheetMappingResult;
  fileType: FileMapping;
}

export const findMapping = (reader: SheetReader): FileMappingResult | null => {
  const defaultId = reader.getFirstId();

  for(const fileType of fileTypes) {
    let matchingSheet = true;
    let results = [];

    for(const sheetMapping of fileType.sheets) {
      const sheetId = sheetMapping.name ?? defaultId;
      const sheet = reader.readSheet(sheetId, sheetMapping.data_row_offset, sheetMapping.data_col_offset);

      if(sheet == null) {
        matchingSheet = false;
        results = [];
        break;
      }

      const sheetCols = sheet.getColumnNames();
      const expectedCols = sheetMapping.columns;
      const expectedCells = sheetMapping.cells;

      if(expectedCells != undefined && expectedCells.length != 0) {
        if(cellsMatch(expectedCells, sheet)) {
          results.push({ mapping: sheetMapping, sheet });
        } else {
          matchingSheet = false;
          results = [];
          break;
        }
      } else if(expectedCols != undefined && expectedCols.length != 0) {
        if(columnsMatch(expectedCols, sheetCols)) {
          results.push({ mapping: sheetMapping, sheet });
        } else {
          matchingSheet = false;
          results = [];
          break;
        }
      } else {
        matchingSheet = false;
        results = [];
        break;
      }

    }

    if(!matchingSheet) {
      continue;
    } else {
      // console.log("fileType matched", fileType.name)
      return { fileType, sheets: results };
    }
  }

  return null;
}
