import * as React from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web'

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { SiteView } from './site';
import { Home } from './home';
import { Boxes } from './boxes';
import { GlobalHeader } from './global_header';
import { CalculateAddress } from './calculate_addresses';
import { SiteParamsView } from './site_params';
import { SiteCommView } from './site_communication';
import { TrackerHistoryView } from './tracker_history';
import { SiteHistoryView } from './site_history';
import { SiteCommentView } from './site_comment';

const SiteRoute: React.FC = () => {
  const { site } = useParams<{ site: string }>();
  return <SiteView key={site} id={site!} />;
}

const SiteParamsRoute: React.FC = () => {
  const { site } = useParams<{ site: string }>();
  return <SiteParamsView key={site} id={site!} />;
}

const SiteCommunicationRoute: React.FC = () => {
  const { site } = useParams<{ site: string }>();
  return <SiteCommView key={site} id={site!} />;
}

const SiteCommentRoute: React.FC = () => {
  const { site } = useParams<{ site: string }>();
  return <SiteCommentView key={site} id={site!} />;
}

const SiteHistoryRoute: React.FC = () => {
  const { site } = useParams<{ site: string }>();
  return <SiteHistoryView key={site} id={site!} />;
}

const TrackerHistoryRoute: React.FC = () => {
  const { site, tracker } = useParams<{ site: string, tracker: string }>();
  return <TrackerHistoryView key={tracker} siteId={site!} trackerId={tracker!} />;
}

interface StatusSiteProps {
  apiClient: string;
}

const StatusSite: React.FC<StatusSiteProps> = (props) => {
  const { children } = props;

  return (
    <Layout {...props}>
      <div className="status_site">
        {children}
      </div>
    </Layout>
  );
}

interface LayoutProps {
  apiClient: string;
}

const Layout: React.FC<LayoutProps> = ({ apiClient, children }) => {
  const { keycloak } = useKeycloak();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    keycloak.logout();
  };

  const canManageBoxes = keycloak?.hasResourceRole('boxes_write', apiClient) ?? false;

  return (
    <div className="world">
      <div className="action_top_bar">
        { canManageBoxes ? <Button dense tag={Link} to="/boxes">Box Management</Button> : null }
        <Button dense tag={Link} to="/calcaddress">Address Calculation</Button>
        <Button dense onClick={handleLogout}>Logout</Button>
      </div>
      <GlobalHeader />
      {children}
    </div>
  );
}

export interface AppProps {
  apiClient: string;
}

export const App: React.FC<AppProps> = (props) => {
  const { apiClient } = props;
  const { initialized, keycloak } = useKeycloak();

  if(!initialized) {
    return <StatusSite {...props}>Checking login ...</StatusSite>;
  }

  if(!keycloak.authenticated) {
    return (
      <StatusSite {...props}>
        Not logged in
        <Button raised onClick={() => keycloak.login()}>Login</Button>
      </StatusSite>
    );
  }

  // TODO use other role? fine grained control read/write/create?
  if(!keycloak.hasResourceRole('boxes_read', apiClient)) {
    return (
      <StatusSite {...props}>
        Account does not have required privileges
        <button onClick={() => keycloak.logout()}>Logout</button>
      </StatusSite>
    );
  }

  return (
    <Layout {...props}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="site/:site/params" element={<SiteParamsRoute />} />
        <Route path="site/:site/communication" element={<SiteCommunicationRoute />} />
        <Route path="site/:site/comment" element={<SiteCommentRoute />} />
        <Route path="site/:site/sitehistory" element={<SiteHistoryRoute />} />
        <Route path="site/:site/tracker/:tracker/history" element={<TrackerHistoryRoute />} />
        <Route path="site/:site" element={<SiteRoute />} />
        <Route path="boxes" element={<Boxes />} />
        <Route path="calcaddress" element={<CalculateAddress />} />
      </Routes>
    </Layout>
  );
}
