import * as React from 'react';
import { useMemo } from 'react';

import { Radio } from '@rmwc/radio';
import '@rmwc/radio/styles';

import { TrackerDataMap, TrackerData, SelectableStateType } from '../types';
import { getTrackerState } from '../state_calc';

interface TrackerStateRadioProps {
  label: string;
  count: number;
  type: SelectableStateType;
  value: SelectableStateType;
  update: (data: SelectableStateType) => void;
}

const TrackerStateRadio: React.SFC<TrackerStateRadioProps> = ({ label, count, type, value, update }) => {
  const handleChange = () => {
    update(type);
  };

  return <Radio value={type} checked={value === type} onChange={handleChange}>
    {label} ({count} trackers)
  </Radio>;
};

export interface TrackerStateChoiceProps {
  trackers?: TrackerData[];
  value: SelectableStateType;
  update: (data: SelectableStateType) => void;
};

export const TrackerStateChoice: React.SFC<TrackerStateChoiceProps> = ({ trackers, value, update }) => {
  const counts = useMemo(() => {
    const result = {
      error: 0,
      warning: 0,
      okay: 0,
    };

    if(trackers == null) {
      return result;
    }

    trackers.forEach((value) => {
      const key = getTrackerState(value);
      result[key] += 1;
    });

    return result;
  }, [trackers]);

  return <span className="state_choice">
    <TrackerStateRadio label="All" count={trackers?.length ?? 0} type="all" value={value} update={update} />
    <TrackerStateRadio label="Invalid" count={counts.error} type="error" value={value} update={update} />
    <TrackerStateRadio label="Partial" count={counts.warning} type="warning" value={value} update={update} />
    <TrackerStateRadio label="Complete" count={counts.okay} type="okay" value={value} update={update} />
  </span>
};
