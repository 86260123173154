import { isEqual } from "lodash";
import {  CommData, ParameterData, SheetMapping, TrackerData, UploadData } from "./types";
import { SheetReader, SheetView } from "./sheet_reader";
import { convertNames } from "./convert_names";
import { matchBoxes } from "./api";

export type TrackerDelta = Map<string,string|number|null>;
export type TrackerDeltaMap = Map<string,TrackerDelta>;

interface TrackerUpdateEntry {
  id: string;
  tracker: TrackerData;
  delta: TrackerDelta;
}

interface TrackerInsertEntry {
  id: string;
  tracker?: undefined;
  delta: TrackerDelta;
}

interface TrackerDeleteEntry {
  id: string;
  tracker: TrackerData;
  delta?: undefined;
}

type TrackerChangeEntry = TrackerUpdateEntry | TrackerInsertEntry | TrackerDeleteEntry;

const parseNumber = (data: string|number|null): number|null => {
  if(typeof data === 'string') {
    data = parseFloat(data);

    if(isNaN(data)) {
      return null;
    }

    return data;
  } else {
    return data;
  }
};

const trackerArrayToMap = (trackers: TrackerData[]): Map<string,TrackerData> => {
  return new Map(trackers.map((tracker): [string, TrackerData] => [tracker.id, tracker]));
}

const parsers = new Map(Object.entries({
  cleaning: parseNumber,
  lat: parseNumber,
  long: parseNumber,
  elevation: parseNumber,
  calibration: parseNumber,
}));

export const serialToAddress = (serial: string): string | null => {
  if(!serial.match(/^\d{11}$/)) {
    return null;
  }

  const fa = Number(serial.substr(0, 5));
  const sn = Number(serial.substr(5));

  const raw = fa + (sn << 18);
  const hex = raw.toString(16).padStart(8, '0');

  const swapped = hex.match(/.{2}/g)!.reverse().join('');

  return swapped;
};

const parseRow = (raw: Record<string,string|number|null>): Record<string,string|number|null> => {
  const entries = Object.entries(raw).map(([key, value]): [string, string|number|null] => {
    const parser = parsers.get(key);

    if(parser != null) {
      value = parser(value);
    }

    return [key, value];
  });

  return Object.fromEntries(entries);
};

export const enrichTrackerAddresses = async (changes: TrackerChangeEntry[], token: string): Promise<TrackerChangeEntry[]> => {
  const lookupBoxes = new Set<string>();
  const handledTrackers = new Set<string>();

  for(const { id, tracker, delta } of changes) {
    if(delta == null) {
      // this is a delete, nothing to do here
      continue;
    }

    if(!delta.has('controller')) {
      // delta has no box serial to look up
      continue;
    }

    if(delta.has('address')) {
      // delta has address already set
      continue;
    }

    const deltaController = delta.get('controller')

    if(tracker != null && deltaController === tracker.controller && tracker.address != null) {
      // tracker already has that controller and an address for it
      continue;
    }

    handledTrackers.add(id);
    lookupBoxes.add(String(deltaController));
  }

  if(lookupBoxes.size === 0) {
    return changes;
  }

  const serials = await matchBoxes(Array.from(lookupBoxes.keys()), token);

  return changes.map((change): TrackerChangeEntry => {
    const { id, delta } = change;

    if(delta == null) {
      return change;
    }

    const controller = String(delta.get('controller'));

    if(!handledTrackers.has(id)) {
      return change;
    }

    const circuit = serials[controller];

    const newDelta = new Map(delta);
    newDelta.set('address', circuit != null ? serialToAddress(circuit) : null);

    return {
      ...change,
      delta: newDelta,
    }
  });
};

export const loadSheetDeltas = (sheet: SheetView, mapping: SheetMapping): Map<string,TrackerDelta> => {
  const rows = Array.from(sheet.getData()).map((line) => {
    return convertNames(line, mapping);
  });

  const entries = rows.map((row): [string, TrackerDelta] | undefined => {
    if(row.id == null) {
      return undefined;
    }

    const deltaEntries = Object.entries(row)
      .filter(([key, value]) => {
        return key !== 'id' && value != null;
      }).map(([key, value]): [string, string|number|null] => {
        return [key, value === '-' ? null : value];
      });

    return [String(row.id), new Map(deltaEntries)];
  }).filter((entry): entry is [string, TrackerDelta] => {
    return entry != null;
  });

  return new Map(entries);
}

export const loadParameter = (sheet: SheetView | undefined, mapping: SheetMapping | undefined): ParameterData | undefined => {
  if(sheet == undefined || mapping == undefined || mapping.cells == undefined) {
    return undefined;
  }

  const data: ParameterData = {};
  for(const cell of mapping.cells) {
    const offset = cell.col_offset ?? 1;
    const value = sheet.getCell(cell.col + offset, cell.row);
    if(cell.mapValue) {
      data[cell.key] = cell.mapValue(value);
    } else {
      data[cell.key] = value;
    }
  }

  return data;
}

export const loadCommunication = (sheet: SheetView | undefined, mapping: SheetMapping | undefined): CommData[] | undefined => {
  if(sheet == undefined || mapping == undefined || mapping.columns == undefined) {
    return undefined;
  }

  const rows = Array.from(sheet.getData()).map((line) => {
    return convertNames(line, mapping);
  }).filter( (line): line is CommData => line['id'] != null && line['id'] != '-' );

  return rows;
}

export const loadUploadDeltas = (upload: UploadData): Map<string,TrackerDelta> => {
  const entries = upload.trackers.map((tracker): [string, TrackerDelta] => {
    const { id, ...other } = tracker;

    return [id, new Map(Object.entries(other))];
  });

  return new Map(entries)
}

export const calcTrackerChanges = (trackers: TrackerData[], deltas: Map<string,TrackerDelta>, merge=true): TrackerChangeEntry[] => {
  const trackerMap = trackerArrayToMap(trackers);

  const unmappedTrackers = new Set<string>(trackerMap.keys());

  const changes = Array<TrackerChangeEntry>();

  for(const [id, delta] of deltas.entries()) {
    const tracker = trackerMap.get(id);

    if(tracker == null) {
      if(!merge) {
        // tracker not found, create it
        changes.push({ id, delta });
      }

      continue;
    }

    // update tracker
    changes.push({ id, tracker, delta });

    // mark tracker as mapped
    unmappedTrackers.delete(id);
  }

  if(!merge) {
    for(const id of unmappedTrackers) {
      // remove unmapped trackers
      const tracker = trackerMap.get(id)!;
      changes.push({ id, tracker });
    }
  }

  return changes;
}

export const applyTrackerChanges = (trackers: TrackerData[], changes: TrackerChangeEntry[]): TrackerData[] => {
  if(changes.length === 0) {
    return trackers;
  }

  const trackerMap = trackerArrayToMap(trackers);

  for(const { id, tracker, delta } of changes) {
    if(delta == null) {
      trackerMap.delete(id);
      continue;
    }

    const newTracker = tracker != null ?  { ...tracker } : { id };

    for(const [key, value] of delta.entries()) {
      if(value != null) {
        newTracker[key] = value;
      } else {
        delete newTracker[key];
      }
    }

    trackerMap.set(id, newTracker);
  }

  return Array.from(trackerMap.values());
};

export const loadSheet = (file: File) => {
  return new Promise<SheetReader>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async (loadEvent) => {
      try {
        const data = fileReader.result;
        const sheetReader = new SheetReader(data, file.name);
        resolve(sheetReader);
      } catch {
        reject(new Error("Unable to parse file"));
      }
    };

    fileReader.onerror = () => {
      reject(new Error("Unable to read file"));
    };

    fileReader.readAsBinaryString(file);
  });
};

export const getChangeStats = (changes: TrackerChangeEntry[], prev: TrackerData[], next: TrackerData[]) => {
  let updates = 0;
  let inserts = 0;
  let deletes = 0;
  let unchanged = 0;

  const prevMap = trackerArrayToMap(prev);
  const nextMap = trackerArrayToMap(next);

  for(const change of changes) {
    if(change.delta == null) {
      deletes += 1;
    } else if(change.tracker == null) {
      inserts += 1;
    } else {
      updates += 1;

      if(isEqual(prevMap.get(change.id), nextMap.get(change.id))) {
        unchanged += 1;
      }
    }
  }

  return { updates, inserts, deletes, unchanged };
}

