import * as React from 'react';
import { useState, ChangeEvent, useRef } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';

import { SiteData } from '../types';
import { FileDialog } from './file_dialog';
import { JSONFileDialog } from './import_json_dialog';

export interface ImportButtonProps {
  site: SiteData | undefined;
};

export const ImportButton: React.FC<ImportButtonProps> = ({ site }) => {
  const [file, setFile] = useState<File>();
  const [dragActive, setDragActive] = React.useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: React.DragEvent<any> | DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if(e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if(e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if(e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  }

  React.useEffect(() => {
    document.addEventListener('dragenter', handleDrag);
    return () => document.removeEventListener('dragenter', handleDrag);
  }, []);


  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if(files == null) {
      setFile(undefined);
      return;
    }

    setFile(files[0]);
  };

  const resetFile = () => {
    setFile(undefined);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  let dialog = null;
  if(site != null && file != null) {
    if(file.type === 'application/json') {
      dialog = <JSONFileDialog site={site} file={file} onClosed={resetFile} />;
    } else {
      dialog = <FileDialog site={site} file={file} onClosed={resetFile} allowModeChange />;
    }
  }

  const btn_css = 'margin_top' + (dragActive ? ' dnd_focus' : '');
  const btn_val = dragActive ? 'Drop & Import File' : 'Import File';

  return <>
    {dialog}
    <Button disabled={site == null} className={btn_css} raised onClick={handleClick} title="Import file via drag and drop or click">{btn_val}</Button>
    <input style={{ display: 'none' }} ref={fileInputRef} type="file" value="" onChange={onChange} />
    {dragActive && <div className="dnd_target" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
  </>;
};
